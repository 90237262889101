import React from "react";

import Scroll from 'react-scroll';

import { HomeLogoSvg } from "../assets/svg/";

import "../css/Home.css";
import CustomNavigationLink from "./CustomNavigationLink/CustomNavigationLink";

const Home = ({striped}) => (
    <div className={`section ${striped}`} id='home'>
        <div className='content'>
            <div className="center">
                <div className="logohome">
                    <HomeLogoSvg />
                </div>

                <h1 className='baseline'>
                    <CustomNavigationLink className="pointer" activeClass="selected" to="maison">{"{ Manufacture d'agents IA }"}</CustomNavigationLink>
                </h1>
            </div>
        </div>
    </div>
);

export default Home;
