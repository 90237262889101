import { Link as ScrollLink } from 'react-scroll';
import { useLocation } from 'react-router-dom';
import { NavHashLink as Link } from 'react-router-hash-link';

const CustomNavigationLink = ({ to, children, ...props }) => {
  const location = useLocation();

  // Si nous sommes sur la page d'accueil, utiliser react-scroll
  if (location.pathname === '/') {
    console.log(location.pathname);
    return (
      <ScrollLink
        to={to}
        smooth={true}
        duration={500}
        spy={true}
        {...props}
      >
        {children}
      </ScrollLink>
    );
  }

  // Sinon, rediriger vers la page d'accueil avec le hash
  return (
    <Link
      to={`/#${to}`}
      {...props}
    >
      {children}
    </Link>
  );
};

export default CustomNavigationLink;