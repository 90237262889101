import React, { useEffect, useState } from 'react';

// -------------------------------------------------------------------------
// Sous composants

import Project from './project/Project';
import Credits from './credits/Credits';
import LogoItem from './logoitem/LogoItem';

import '../css/project-grid.css';


// -------------------------------------------------------------------------
// Icons

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faBolt, 
    faRandom,
    faSortNumericDown,
    faMale,
    faFemale,
    faExternalLinkAlt,
    faFileExcel
    } from '@fortawesome/free-solid-svg-icons';
import { faGitlab, faYoutube } from "@fortawesome/free-brands-svg-icons";


// -------------------------------------------------------------------------
// images SVG

import {
    LightgridSvg,
    ZappybotSvg,
    BlockchainSvg,
    OcarinaSvg,
    HTTPPostItSvg,
    PlasmaBallSvg,
    S2MotsSvg,
    RefrigirateurSvg,
    LuigiSvg,
    LabelloSvg,
    AtelierSvg,
    TextoSvg,
    FreedayOfficielSvg,
    GrowdditySvg,
    ParkourSvg,
    FaismoisigneSvg,
    OctoprintSvg,
    LunetariumSvg,
    FaceSvg,
    HandSvg,
    NewsSvg,
    News2Svg,
    HelpSvg,
    MusicSvg,
    CookieSvg,
    TownSvg,
    SparklingHeartSvg,
    SarahSvg,
    CrapoSvg,
    CaptainSvg,
    PpsSvg
} from '../assets/svg/';

import bourseSvgPath from '../img/bourse.svg';
import blogSvgPath from '../img/blog.svg';
import movaicodeSvgPath from '../img/movaicode-bold.svg';


// -------------------------------------------------------------------------
// Composant principal

const Atelier = () => {

    const [viewNbItems, setViewNbItems] = useState(0);
    
    const handleViewMoreItems = (e) => {
        e.target.blur();
        setViewNbItems(prevViewNbItems => prevViewNbItems + 1);
    }

    useEffect(() => {
        window.scroll({ top: 0, behavior: "smooth" });
    }, []);

    return(
        <section>
            <div className="content">
                <h1 className="sectiontitle">
                    <span className="red">P</span>roduits de l'Atelier
                </h1>
                <div className="block-content">
                    
                    <div className="project-grid">

                        <div className="project-grid-item">
                            <Project
                                SvgComponent={AtelierSvg}
                                name="Votre projet ?"
                                type="pasencore"
                            >
                                L'<span className='red'>A</span>telier met en oeuvre les projets proposés par tout membre de l'équipe <span className='red'>c</span>oddity, dès lors
                                qu'ils éveillent l'intérêt, sont réalisables au sens faisables en un temps donné et, pourquoi pas, commercialisables !
                            </Project>
                        </div>

                        <div className="project-grid-item">
                            <Project
                                SvgComponent={FreedayOfficielSvg}
                                name="Freeday"
                                type="opensource"
                            >
                                Créé, finalisé en Atelier, commercialisé et ouvert à l'open source, 
                                Freeday permet de <b>gérer facilement les absences de salariés ou bénévoles d'associations ou TPE</b>.
                                Avec Freeday, adieu la feuille <FontAwesomeIcon icon={faFileExcel} /> partagée !&nbsp;
                      
                                <a className="blue" href="https://freeday-app.com/" target="_blank" rel="noopener noreferrer">
                                    freeday-app.com<FontAwesomeIcon icon={faExternalLinkAlt} className="iconMargin" />
                                </a>

                            </Project>
                        </div>

                        <div className="project-grid-item">
                            <Project
                                SvgComponent={CaptainSvg}
                                name="Captain Docker"
                                type="prod"
                            >
                                Notre prmieère expérience de <b>fine tuning avec Llama2</b> !
                                Objectif : <b>lancer des commandes docker</b> en langage naturel : <i>Hey bro donne moi tous les containers
                                    qui tournent depuis la semaine dernière !</i>.
                            </Project>
                        </div>

                        <div className="project-grid-item">
                            <Project
                                SvgComponent={SarahSvg}
                                name="Sarah"
                                type="prod"
                            >
                                Pour expérimenter les LLM appliqués au <b>recrutement</b>,
                                nous avons conçu une assistante virtuelle nommée <b>Sarah Hati</b>.
                                Son <a href="https://www.linkedin.com/in/sarah-hati-343340292/" target="_blank"
                                 rel="noopener noreferrer">profil LinkedIn</a> est en lien avec une app paramétrée
                                avec un <b>prompt</b> OpenAI conséquent.
                                Elle <b>contacte des candidats</b>, <b>répond aux questions</b> et <b>écrit des posts</b>. <a href="https://www.linkedin.com/in/sarah-hati-343340292/" target="_blank"
                                 rel="noopener noreferrer">Essayez</a> !
                            </Project>
                        </div>
                        
                        <div className="project-grid-item">
                            <Project
                                SvgComponent={SparklingHeartSvg}
                                name="Love coach"
                                type="assemblage"
                            >
                                A l'image de Will Smith et dans la lignée des plus grands <b>pickup artists</b>,
                                nous développons une assistante virtuelle programmée pour assister dans 
                                la <b>préparation</b>, la <b>réalisation</b> de rendez-vous amoureux IRL.
                                Technos : OpenAI, longue expérience de l'équipe, etc.
                            </Project>
                        </div>

                        <div className="project-grid-item">
                            <Project
                                SvgComponent={CrapoSvg}
                                name="Crapo"
                                type="prod"
                            >
                                Enfin une app pour <b>saisir les temps</b> / imputations sur les projets !
                                Merci Lord pour ce fabuleux travail qui simplifie la vie à la Direction Technique.
                                Code partageable sur demande par courrier postal.
                            </Project>
                        </div>

                        <div className="project-grid-item">
                            <Project
                                SvgComponent={PpsSvg}
                                name="PPS"
                                type="prod"
                            >
                                Notre label Punky Places Society récompense les <b>meilleurs entreprises</b> (en toute objectivité et modestie),
                                qui se voient recevoir leur certification sous la forme d'un NFT.
                                Propulsé par des <b>smart contracts</b> sur la blockchain <b>Ethereum</b>,
                                la labelisation PPS compte devenir une organisation autonome et décentralisée.&nbsp;
            
                                <a className="blue" href="https://punky.place/" target="_blank" rel="noopener noreferrer">
                                    punky.place<FontAwesomeIcon icon={faExternalLinkAlt} className="iconMargin" />
                                </a>
                            </Project>
                        </div>

                        <div className="project-grid-item">
                            <Project
                                SvgComponent={MusicSvg}
                                name="Synthétiseur modulaire"
                                type="prod"
                            >
                                Nos métaleux développent une app permettant de <b>générer du son</b>, de le <b>moduler</b> et de le <b>jouer</b>.
                                On peut ajouter des sources, des instruments ou des effets, en série ou en parallèle.
                                Le tout avec Vue, canvas et webaudioapi.
                                Après 2 itérations, le projet devrait être proposé <b>en démo très bientôt</b> !
                            </Project>
                        </div>

                        <div className="project-grid-item">
                            <Project
                                SvgComponent={() => <LogoItem imgSvg={bourseSvgPath} />}
                                name="Bourse"
                                type="suspendu"
                            >
                                Coddity <b>parraine des étudiant(e)s</b> en informatique en prenant en charge
                                une partie de leurs <b>frais de scolarité</b>.
                                Les lauréat(e)s sont sélectionnés à l'issue d'un <b>concours de développement</b> annuel.
                                La <b>7ème édition</b> est en préparation !

                                <a className="blue" href="https://bourse.coddity.com/" target="_blank" rel="noopener noreferrer">
                                    bourse.coddity.com<FontAwesomeIcon icon={faExternalLinkAlt} className="iconMargin" />
                                </a>
                            </Project>
                        </div>

                        { (viewNbItems >= 1) &&
                            <>

                        <div className="project-grid-item">
                            <Project
                                SvgComponent={() => <LogoItem imgSvg={movaicodeSvgPath} />}
                                name="Movai code"
                                type="live"
                            >
                                Bien coder, optimiser, respecter des conventions... <b>Y EN A MARRE</b> !
                                Bienvenue au concours du pire dev de France : montrez au monde <b>à quel point vous pouvez être nul(le)</b> !
                                Tous les mois, une fonction à coder de la pire des manières : mais attention il faut
                                qu'elle marche ! Plus facile à dire qu'à faire...&nbsp;
                                <a className="blue" href="https://github.com/CoddityTeam/movaicode/" target="_blank" rel="noopener noreferrer">
                                    Tentez d'être movai<FontAwesomeIcon icon={faExternalLinkAlt} className="iconMargin" />
                                </a>
                            </Project>
                        </div>

                        <div className="project-grid-item">
                            <Project
                                SvgComponent={() => <LogoItem imgSvg={blogSvgPath} />}
                                name="Blog"
                                type="live"
                            >
                                Sur notre blog nous publions des articles <b>d'actualité technique</b> (langages, frameworks, outils, data) ainsi que
                                nos réflexions sur des sujets plus humains tels que le <b>recrutement</b>, la <b>gestion des compétences</b>, l'<b>accessibilité</b>, etc.&nbsp;
                                <a className="blue" href="https://blog.coddity.com/" target="_blank" rel="noopener noreferrer">
                                    blog.coddity.com<FontAwesomeIcon icon={faExternalLinkAlt} className="iconMargin" />
                                </a>
                            </Project>
                        </div>
                        
                        <div className="project-grid-item">
                            <Project
                                SvgComponent={HelpSvg}
                                name="Elpy"
                                type="suspendu"
                            >
                                Vous partez en randonnée itinérante ou dans des <b>coins reculés potentiellement dangereux</b> ? Avec Elpy,
                                paramétrez un <b>message d'urgence</b> qui sera envoyé à vos proches s'il vous arrive quelque chose.
                                Ce dernier, ainsi que votre <b>dernière position GPS connue</b>, sont stockés sur nos serveurs et émis
                                si vous ne vous manifestez pas régulièrement sur l'application. Et vous êtes sauvé(e) !
                            </Project>
                        </div>

                        <div className="project-grid-item">
                            <Project
                                SvgComponent={ParkourSvg}
                                name="Parkour"
                                type="live"
                            >
                                Nous avons développé notre propre Coddity Starter Pack ! Une <b>formation technique</b> pour nos nouveaux Artisans
                                à réaliser lors de leur <b>première semaine d'intégration</b>. Objectif : se mettre à niveau
                                sur nos <b>bonnes pratiques</b> et spécialités : Front, Back, Tests, Git et Système.
                            </Project>
                        </div>
                            
                        <div className="project-grid-item">
                            <Project
                                SvgComponent={HandSvg}
                                name="Handdity"
                                type="prototype"
                            >
                                Frankenstein est de retour ! Parce que partager sa vie avec un être vivant c'est <b>sooo 2018</b>,
                                nous construisons une <b>main articulée robotisée</b>. A l'aide de <b>servomoteur, d'arduino et d'une cinquantaine de pièces</b> imprimées
                                en 3D, nous arrivons à contrôler la main et les doigts via l'arduino ou <b>un leap motion</b> !
                            </Project>
                        </div>

                        <div className="project-grid-item">
                            <Project
                                SvgComponent={GrowdditySvg}
                                name="Growddity"
                                type="suspendu"
                            >
                                Chez Coddity, on aime bien <b>manger</b>, donc on a décidé de créer notre propre <b>serre connectée</b>. Et pour obtenir les meilleurs produits,
                                quoi de mieux que de la contrôler en fonction des conditions climatiques, avec un Arduino, du python et un peu d'électronique ?
                                Pour l'instant, on relève la température, l'humidité et la luminosité. La suite : réagir automatiquement en fonction de seuils
                            </Project>
                        </div>

                        <div className="project-grid-item">
                            <Project
                                SvgComponent={FaismoisigneSvg}
                                name="Fais-moi signe"
                                type="prototype"
                            >
                                Pour faciliter la communication avec les personnes <b>muettes et/ou sourdes</b> (enseignement, assistance),
                                nous développons un outil de <b>détection de la langue des signes sur webcam</b>.
                                Partis d'un algorithme de deep learning pré-entraîné pour la reconnaissance des lettres A et B,
                                nous l'étendons à l'alphabet complet par nous-mêmes grâce à de l'<b>image augmentation</b> et des <b>réseaux de neurones</b> !
                            </Project>
                        </div>

                        <div className="project-grid-item">
                            <Project
                                SvgComponent={NewsSvg}
                                name="Infox News"
                                type="prototype"
                            >
                                Comment faire le <b>tri entre info et fake news</b> ? Notre <b>extension firefox</b> permet à sa communauté d'estimer 
                                la véracité de portions d'articles en y liant des sources fiables : chacun peut <b>étayer ou réfuter une info</b>, ou bien demander une source.
                                On estime donc d'un coup d'oeil la fiabilité globale d'un article !
                            </Project>
                        </div>

                        <div className="project-grid-item">
                            <Project
                                SvgComponent={S2MotsSvg}
                                name="sexe-de-mots"
                                type="prototype"
                            >
                                Selon Wenchao, la genre des mots en français est une cauchemar !
                                Ni un, ni deux, avec Axel et Olivier, il a développé sexe-de-mots, un <b>jeu</b> web en Rails/Vue.js réutilisant la <b>swipe de Tinder</b> pour
                                choisir si la mot est masculin <FontAwesomeIcon icon={faMale}/> ou féminin <FontAwesomeIcon icon={faFemale}/>.
                                Recommandé pour celleux qui s'arrachent la cheveux et pour qui la français est une parcours du combattant !
                            </Project>
                        </div>

                        </>
                        }

                        { (viewNbItems >= 2) &&
                            <>
                            
                        <div className="project-grid-item">
                            <Project
                                SvgComponent={LunetariumSvg}
                                name="Lunetarium"
                                type="prototype"
                            >
                                Parce que le style a son importance, nous vous aidons à trouver LA paire de lunettes de vos rêves sans quitter votre écran,
                                grâce à notre <b>cabine d'essayage virtuelle</b>.
                                Une solution soumise à de fortes contraintes de performances : accès à la caméra, repérage des yeux et superposition de filtres !
                                Le tout 100% frontend grâce à ml5.js.
                            </Project>
                        </div>
                            
                        <div className="project-grid-item">
                            <Project
                                SvgComponent={TextoSvg}
                                name="Textographe"
                                type="prototype"
                            >
                                Nos Data Scientists planchent sur un <b>framework de traitement du texte</b>, en français s'il vous plaît, qui permet de nettoyer plus rapidement 
                                les jeux de données textuels en tout genre et ainsi libérer plus de temps pour le coeur du sujet de nos projets de NLP/NLU : l'analyse, le clustering et la classification. 
                            </Project>
                        </div>

                        <div className="project-grid-item">
                            <Project
                                SvgComponent={FaceSvg}
                                name="Volte/Face"
                                type="prototype"
                            >
                                Nicolas Cage n'a qu'à bien se tenir. Pour sensibiliser les jeunes générations aux dangers de la reconnaissance faciale,
                                Jérémy a développé une application de <b>détection de visage à partir d'une simple photo LinkedIn</b>.
                                Ce projet de traitement vidéo et ML est porté en web sur un Rasp et nous reconnaît à... <b>90%</b>... Inquiétant ?
                            </Project>
                        </div>

                        <div className="project-grid-item">
                            <Project
                                SvgComponent={BlockchainSvg}
                                name="CRA Blockchain"
                                type="prototype"
                            >
                                Revisiter le Compte-Rendu d'Activité avec des chaînes et des blocs ? 
                                Défi relevé par Michel ! Pour tester les avantages et les limites par rapport à une base de données standard, 
                                Il s'est lancé sur un prototype de <b>Smart Contract</b> sur Ethereum, avec Solidity, Truffle, Embark, React et Redux.
                                Et ça marche ! <br/>
                                <a href="https://blog.coddity.com/article/cra-blockchain/">Lire l'article complet sur notre blog.</a>
                            </Project>
                        </div>

                        <div className="project-grid-item">
                            <Project
                                SvgComponent={HTTPPostItSvg}
                                name="HTTP POST-it"
                                type="tobeimproved"
                            >
                                Marre de resaisir les post-its de vos séances de design thinking ou vos scrum ?
                                Nous aussi ! C'est pourquoi on développe un outil de <b>reconnaissance de texte</b> sur post-its pour en extraire le contenu
                                vers un trello ou mindmap.
                                Le tout fait-main en Python, sans API d'OCR, juste des libs de Machine Learning. Et bien entendu sans conservateurs.
                                Actuellement, la perf est à 80%, alors on planche sur l'optimisation.
                            </Project>
                        </div>
                            
                        <div className="project-grid-item">
                            <Project
                                SvgComponent={LightgridSvg}
                                name="Lightgrid"
                                type="prod"
                            >
                                Comme on en a assez de toujours avoir à faire de la surcharge de Bootstrap, nous avons conçu un <b>générateur de framework CSS</b>.
                                Celui-ci permet de créer une charte graphique Web, de façon extrêmement personnalisable et légère, sans même vraiment connaître le CSS !
                                Vous pouvez de plus proposer vos thèmes à la communauté et être rémunéré s'ils sont appréciés !
                                En prod ici même !
                            </Project>
                        </div>

                        </>
                        }

                        { (viewNbItems >= 3) &&
                            <>

                        <div className="project-grid-item">
                            <Project
                                SvgComponent={LuigiSvg}
                                name="Luigi Neural Networks"
                                type="prototype"
                            >
                                JE MARCHE SEUL !
                                Terminer le niveau 1 de Super Mario Bros. <b>sans intervention humaine</b> ??
                                Mais si c'est possible avec la carte Kiwi... Mais surtout avec un réseau de neurones entraîné par un algorithme génétique.
                                Le code source de l'algo est bientôt dispo, demandez conseil à votre pharmacien. Et d'ici là, n'hésitez pas à 
                                <a href = "https://blog.coddity.com/article/mario-neural-network"> lire l'article on ne peut plus précis de Clément sur notre blog.</a>
                            </Project>
                        </div>

                        <div className="project-grid-item">
                            <Project
                                SvgComponent={LabelloSvg}
                                name="Labello"
                                type="prototype"
                            >
                                80% du temps passé sur des projets de Machine Learning est consacré à la préparation des données.
                                De ce constat est né Labello : il permet d'<b>accélérer la labellisation d'images</b>, en accès concurrent sur un dataset !
                                Le tout en Python avec Django.
                                Médicament délivré sur ordonnance, parlez-en à votre médecin traitant.
                            </Project>
                        </div>

                        <div className="project-grid-item">
                            <Project
                                SvgComponent={OcarinaSvg}
                                name="Thérémine"
                                type="live"
                                url="https://youtu.be/YqThy-u1Y8A"
                            >
                                Que faire avec un <b>Leap motion controller</b>, du React et un peu de temps ? Le remake d'un Thérémine bien sûr !
                                Voyez par vous-mêmes le premier instrument de musique électronique de l'histoire revisité <a href="https://youtu.be/YqThy-u1Y8A">en vidéo <FontAwesomeIcon icon={faYoutube}/></a>.
                                Clone / fork sur <a href="https://gitlab.com/coddity-public/reactleap-theremin.git">Gitlab <FontAwesomeIcon icon={faGitlab} className="iconMargin" /></a>.
                                Expérience réalisée par des professionnels, ne pas tenter de reproduire.
                            </Project>
                        </div>

                        <div className="project-grid-item">
                            <Project
                                SvgComponent={OctoprintSvg}
                                name="Octoprint"
                                type="prototype"
                            >
                                Afin de faciliter l'utilisation de notre <b>imprimante 3D</b>, nous avons installé Octoprint sur un Rasp, connecté en USB.
                                A distance, nos Artisans peuvent maintenant monitorer les indicateurs principaux de l'imprimante, piloter le bras,
                                uploader des modèles, lancer des impressions et en visualiser l'état d'avancement !
                                La suite : un peu de boulot sur le front pour améliorer l'UX.
                            </Project>
                        </div>

                        <div className="project-grid-item">
                            <Project
                                SvgComponent={PlasmaBallSvg}
                                name="Ramble On"
                                type="suspendu"
                            >
                                Matthieu est obsédé par le Rétrogaming, les Raspberry Pi et Python.
                                Ajoutez une caméra et une boule à plasma et vous obtenez un fat <b>générateur de nombre aléatoires</b> <FontAwesomeIcon icon={faRandom}/> <FontAwesomeIcon icon={faSortNumericDown}/>.
                                Les API et le moteur sont développés, il reste maintenant à peaufiner le front en React pour mettre cette machine de guerre à disposition de tout un chacun.
                                Garder sous clé et tenir hors de la portée des enfants.
                            </Project>
                        </div>

                        <div className="project-grid-item">
                            <Project
                                SvgComponent={ZappybotSvg}
                                name="Zappybot"
                                type="prod"
                            >
                                Ali a planché sur un bot slack qui permet de <b>récompenser les bonnes actions</b>.
                                Coup de main sur un irréductible bug ? <span className="blue">&rarr; +1 <FontAwesomeIcon icon={faBolt}/> </span>
                                Partage d'une news intéressante ? <span className="blue">&rarr; +1 <FontAwesomeIcon icon={faBolt}/> </span>
                                Jeu de mots pertinent ? <span className="blue">&rarr; +1 <FontAwesomeIcon icon={faBolt}/> </span>.
                                A la fin de la semaine, on compte les <span className="blue"><FontAwesomeIcon icon={faBolt}/></span> et le plus méritante gagne un verre d'armagnac.
                                Le bot est personnalisable et actuellement en prod sur les slacks de <span className="red">c</span>oddity et de paris.js (CoucouCroissant !)
                            </Project>
                        </div>
                        
                        </>
                        }

                        { (viewNbItems >= 4) &&
                            <>

                        <div className="project-grid-item">
                            <Project
                                SvgComponent={TownSvg}
                                name="Léonard"
                                type="suspendu"
                            >
                                Une partie de l'équipe développe une petite application mobile pour la Mairie
                                de <a href="https://www.ville-saint-leonard.fr/" target="_blank" rel="noopener noreferrer">Saint-Léonard-de-Noblat</a> en
                                Haute Vienne. Il s'agit pour le Maire et son équipe de facilement <b>notifier les habitants de St Léo de divers
                                évènements ou actualités</b>.
                            </Project>
                        </div>

                        <div className="project-grid-item">
                            <Project
                                SvgComponent={RefrigirateurSvg}
                                name="Waste Cost"
                                type="assemblage"
                            >
                                Le projet fil rouge d'Axel est un frigo connecté permettant de <b>réduire le gâchis alimentaire</b> :
                                scan des articles, connexion à openfoodfacts, alertes dates de péremption, recettes adaptées au stock, etc.
                                En sommeil quelques mois, le projet est reparti de plus belle avec une <b>app mobile en React Native</b> !
                            </Project>
                        </div>

                        <div className="project-grid-item">
                            <Project
                                SvgComponent={News2Svg}
                                name="MedRSS"
                                type="suspendu"
                            >
                                Zakaria développe un outil <b>d'analyse quotidienne d'articles de presse</b> :
                                mesure de <b>similarité</b> entre deux articles, <b>classification</b> (politique ou non ?).
                                Pour cela, il parse les flux RSS des éditeurs et les envoie à <b>RoBERTa</b>, un modèle
                                pré-entraîné améliorant le modèle BERT de Google reposant sur des transformers (comme les jouets à Noël).
                            </Project>
                        </div>
                        
                        <div className="project-grid-item">
                            <Project
                                SvgComponent={CookieSvg}
                                name="Noname"
                                type="prototype"
                            >
                                Pour se former à <b>Unity</b>, Anthony a développé un <b>Idle Image Clicker</b> (ou cookie clicker).
                                Il s'agit de cliquer pour récolter des ressources ou des items, et améliorer ses outils.
                                Le projet avance, en vue pour le prochain Atelier un shop pour <b>vendre des items</b> et récolter des PO.
                            </Project>
                        </div>

                        <div className="project-grid-item">
                            <Project
                                SvgComponent={AtelierSvg}
                                name="Votre projet ?"
                                type="pasencore"
                            >
                                L'<span className='red'>A</span>telier met en oeuvre les projets proposés par tout membre de l'équipe <span className='red'>c</span>oddity, dès lors
                                qu'ils éveillent l'intérêt, sont réalisables au sens faisables en un temps donné et, pourquoi pas, commercialisables !
                            </Project>
                        </div>

                            </>
                        }
                        
                    </div>

                    <div className={viewNbItems < 4 ? "viewMoreItems" : "hidden"}>
                        <button className="btn btn-viewMoreItems btn-center" onClick={handleViewMoreItems}>
                            Voir plus
                        </button>
                    </div>

                    <Credits />

                </div>
            </div>
        </section>
    );
}

export default Atelier;