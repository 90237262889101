import React, { useState, useEffect } from 'react';
import Scroll from 'react-scroll';
import { NavHashLink as Link } from 'react-router-hash-link';
import CustomNavigationLink from './CustomNavigationLink/CustomNavigationLink';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

import TopLeftLogo from '../img/top-left-logo.svg';

const Header = () => {

  const [navMenuOpen, setNavMenuOpen] = useState(false);
  const [showLogo, setShowLogo] = useState(false);

  // ComponentDidMount
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // ComponentWillUnmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  const handleScroll = () => {
    setShowLogo(window.location.pathname !== "/" || window.scrollY >= 320);
  }

  const closeNavMenu = () => {
    setNavMenuOpen(false);
  }

  const handleClickNavMenu = () => {
    setNavMenuOpen(!navMenuOpen);
  }

    return (
        <header className={navMenuOpen ? "is-open" : ""}>
          
          {/*<!-- Petit logo coddity si navigation sur mobile ou petite fenêtre -->*/}  
          <CustomNavigationLink className={`logo-top-mobile ${showLogo || navMenuOpen ? 'pointer show' : 'hide'}`} 
            onClick={closeNavMenu} to="home">
          </CustomNavigationLink>
          
          {/*<!-- Mobile Burger button -->*/}
          <div className={navMenuOpen ? "nav-button is-open" : "nav-button"} onClick={handleClickNavMenu}>
            <span></span>
          </div>

          {/*<!-- Mobile navigation menu -->*/}
          <div className={navMenuOpen ? "nav-menu-mobile is-open" : "nav-menu-mobile"}>
            <ul>
              <li className="navbutton pointer">
              <CustomNavigationLink activeClassName="selected" to="maison" onClick={closeNavMenu}>Maison</CustomNavigationLink>
              </li>
              <li className="navbutton pointer">
              <CustomNavigationLink activeClassName="selected" to="services" onClick={closeNavMenu}>Services</CustomNavigationLink>
              </li>
              <li className="navbutton pointer">
              <CustomNavigationLink activeClassName="selected" to="savoirfaire" onClick={closeNavMenu}>Savoir-faire</CustomNavigationLink>
              </li>
              <li className="navbutton pointer">
              <CustomNavigationLink activeClassName="selected" to="produits" onClick={closeNavMenu}>Produits</CustomNavigationLink>
              </li>
              <li className="navbutton pointer">
              <CustomNavigationLink activeClassName="selected" to="references" onClick={closeNavMenu}>Références</CustomNavigationLink>
              </li>
              <li className="navbutton pointer">
              <Link activeClassName="selected" to={{pathname: "/devenir-artisan", state: { userType: "candidat"}}} onClick={closeNavMenu}>Devenir artisan</Link>
              </li>
            </ul>

            <ul className={navMenuOpen ? "socialnetwork center" : ""}>
              <li>
                <a className="pointer" href="https://www.linkedin.com/company/coddity" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedinIn}/> </a>
              </li>
              <li>
                <Link to={{pathname: "/contact", state: { userType: "client"}}} onClick={closeNavMenu} className="pointer"><FontAwesomeIcon icon={faEnvelope}/></Link>
              </li>
            </ul>
            <p className="infolegales navbutton pointer">
              <Link to="/mentions-legales" activeClassName="selected" onClick={closeNavMenu}>Mentions Légales</Link>
            </p>
          </div>

          {/*<!-- Top logo + Coddity-->*/}
          <CustomNavigationLink id="toplogolink" className={`${showLogo ? 'pointer visible' : 'hidden'}`} to="home">
            <div id="toplogo">
              <img src={TopLeftLogo} height="60px" />
            </div>
          </CustomNavigationLink>

          {/*<!-- Desktop Navbar menu -->*/}
          <div className="navbarmenu">
            <ul>
              <li className="navbutton"><CustomNavigationLink className="pointer" activeClass="selected" to="maison" >Maison</CustomNavigationLink></li>
              <li className="navbutton"><CustomNavigationLink className="pointer" activeClass="selected" to="services">Services</CustomNavigationLink></li>
              <li className="navbutton"><CustomNavigationLink className="pointer" activeClass="selected" to="savoirfaire">Savoir-faire</CustomNavigationLink></li>
              <li className="navbutton"><CustomNavigationLink className="pointer" activeClass="selected" to="produits">Produits</CustomNavigationLink></li>
              <li className="navbutton"><CustomNavigationLink className="pointer" activeClass="selected" to="references">Références</CustomNavigationLink></li>
              <li className="navbutton"><Link activeClassName="selected" to="/contact" smooth>Contact</Link></li>
            </ul>
          </div>
        </header>
      );
    }
    
export default Header;          